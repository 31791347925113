<template>
  <v-layout wrap justify-center>
    <iframe
      v-if="projectId && token && isAllowedDemoProject"
      class="demos__iframe w-100"
      :src="demoUrl"
      :style="{ height: height + 'px' }"
      frameborder="0"
      allowfullscreen="allowfullscreen"
      @load="onIframeLoaded"
    ></iframe>
    <v-flex v-else xs12 sm8 md6 lg4 class="mt-5">
      <v-card class="pa-3 text-center">
        <v-card-text class="subtitle-1">
          The selected project is not allowed for Demo, only Construction
          projects are allowed!
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"

export default {
  props: {
    height: {
      type: Number,
      default: null,
    },
  },
  async asyncData({ route }) {
    const projectId = route.params.projectId
    const response = await AdminApi.search.search({
      params: {
        resource: "projects",
        term: projectId,
        email: "construction@evercam.io",
      },
    })

    if (response?.items.length === 0) {
      return {
        isAllowedDemoProject: false,
        projectId,
        token,
      }
    }

    const { token } = await AdminApi.users.impersonateUser(
      "construction@evercam.io"
    )

    return {
      isAllowedDemoProject: true,
      projectId,
      token,
    }
  },
  computed: {
    ...mapStores(useLayoutStore),
    demoUrl() {
      return `${this.$config.public.dashURL}/projects/${this.projectId}${
        this.$route.query?.page ? `${this.$route.query?.page}` : ""
      }${
        this.$route.query?.page?.includes("?") ? "&" : "?"
      }impersonation_token=${this.token}&demo=true`
    },
  },
  methods: {
    onIframeLoaded() {
      this.layoutStore.isMiniSidebar = true
      this.$nextTick(() =>
        document.documentElement.classList.add("overflow-y-hidden")
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.demos {
  &__iframe {
    width: 100%;
    box-shadow: none;
  }
}
</style>
